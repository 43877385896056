
















































































































































































































//@import url(); 引入公共css类
@import url("../assets/font/iconfont.css");
.serviceVue {
  .mould {
    margin: 90px auto;
    h3 {
      text-align: center;
      margin-bottom: 50px;
      font-weight: 800;
    }
    h6 {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.2rem;
    }
    .integrate {
      margin-bottom: 20px;
      .card {
        background: rgba(249, 249, 249, 1);
        border: none;
        height: 100%;
        .card-img,
        .card-img-top {
          height: 60%;
        }
        h4 {
          font-size: 1.2rem;
          font-weight: 800;
        }
        p {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.department {
  .swiper-slide {
    // width: 360px !important;
    height: initial;

    // margin: 10px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fffaf0;
    .iconfont {
      font-size: 10rem;
    }
    h5 {
      font-weight: bolder;
      margin-bottom: 20px;
    }
    p {
      text-indent: 2rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.bule {
  color: #007bff;
}
.yellow {
  color: #ffc107;
}
.pink {
  color: #e83e8c;
}
